import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';
import { amber, green } from '@material-ui/core/colors';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};


export default class ProductHuntSnackBar extends Component {
  constructor(props){
    super(props);
  }
  
  render(){
    const { className, message, onClose, open } = this.props;

    return(
      <Snackbar
        style={{width : '100%'}}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        // autoHideDuration={10000}
        onClose={onClose}
      >
      <SnackbarContent
      className='bg-light text-dark'
      aria-describedby="client-snackbar"
      message={
        <React.Fragment>
        <span id="client-snackbar">
          If you love Dcasso, please vote for us on Product Hunt &nbsp;
        </span>
        <a href="https://www.producthunt.com/posts/dcasso?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-dcasso" target="_blank">
          <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=173183&theme=light" alt="Dcasso - De-centralized doodling app powered by Blockstack | Product Hunt Embed" style={{width: '250px', height: '54px'}} width="250px" height="54px" /></a>
        </React.Fragment>
      }

      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon  />
        </IconButton>,
      ]}
      // {...other}
    />
    </Snackbar>
    )
  }
}
