import React, { Component } from 'react';

export default class Signin extends Component {
  render() {

    const { handleSignIn } = this.props;

    return (
      // <div className="container" id="section-1"  >
        <div className="row align-items-center" style={{ height: '100vh' }}>
          <div className="col-6 signin" style={{ textAlign: 'left', padding:'40px' }}>
            <div className="row">
              <div className="col signin">
                <img src="images/Dcasso-word.png" width="120" height="auto" />
                <br/>
                <h1 className="landing-heading">Ready, Set, Sketch!</h1>
                <p>Can you beat our clock? <i className="fas fa-stopwatch"></i><br/><br/>
                Dcasso is a fun application where you can show off your art skill to the general public. 
                You and the others can interact with each other's art work by&nbsp;
                <i className="far fa-grin-hearts"></i>&nbsp;
                <i className="far fa-grin-squint-tears"></i>&nbsp;
                <i className="fas fa-poo"></i>
                  <br />
                  <br />
                  What Dcasso offers
                  <br />
                  <span className="dcasso-bullet"></span> <b>Fun</b>
                  <br/>
                  <span className="dcasso-bullet"></span> <b>Security</b> 
                  <br/>
                  <span className="dcasso-bullet"></span> <b>More fun</b>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col">
              <button
                className="btn btn-primary btn-lg"
                id="signin-button"
                onClick={handleSignIn.bind(this)}
              >
                Sign In with Blockstack
              </button>
              <br/>
              <small>Our application is built on Blockstack infrastructure, which allows your data to be secure and protects your digital rights (unlike Facebook and Google, we don't read your data). </small>
              <br/>
              <a href="https://blockstack.org/" target="_blank">Learn more about Blockstack</a>
              </div>
            </div>
          </div>
          
        </div>


      // </div>
    );
  }


}
