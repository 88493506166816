import React, { Component } from 'react';
import ColorButton from './ColorButton';

const color1 = 'black';
const color2 = 'blue';
const color3 = 'green';
const color4 = 'red';
const color5 = 'yellow';
const color6 = 'grey';
const color7 = 'blueviolet';
const color8 = 'lime';
const color9 = 'darkorange';
const color10 = 'oldlace';




export default class BasicColorPicker extends Component {
    

    render() {
        return (
            <div className="color-picker-wrapper">
              Colors
              <div>
                <button value={color1} className={'btn color-button '+  (this.props.brushColor === 'black' ? 'color-selected' : '')} onClick={this.props.onClick} style={{background : color1,}}>
                </button>
                <button value={color2} className={'btn color-button '+  (this.props.brushColor === 'blue' ? 'color-selected' : '')} onClick={this.props.onClick} style={{background : color2,}}>
                </button>
                <button value={color3} className={'btn color-button '+  (this.props.brushColor === 'green' ? 'color-selected' : '')} onClick={this.props.onClick} style={{background : color3,}}>
                </button>
                <button value={color4} className={'btn color-button '+  (this.props.brushColor === 'red' ? 'color-selected' : '')} onClick={this.props.onClick} style={{background : color4,}}>
                </button>
                <button value={color5} className={'btn color-button '+  (this.props.brushColor === 'yellow' ? 'color-selected' : '')} onClick={this.props.onClick} style={{background : color5,}}>
                </button>
              </div>
              <div>
                <button value={color6} className={'btn color-button '+  (this.props.brushColor === 'grey' ? 'color-selected' : '')} onClick={this.props.onClick} style={{background : color6,}}>
                </button>
                <button value={color7} className={'btn color-button '+  (this.props.brushColor === 'blueviolet' ? 'color-selected' : '')} onClick={this.props.onClick} style={{background : color7,}}>
                </button>
                <button value={color8} className={'btn color-button '+  (this.props.brushColor === 'like' ? 'color-selected' : '')} onClick={this.props.onClick} style={{background : color8,}}>
                </button>
                <button value={color9} className={'btn color-button '+  (this.props.brushColor === 'darkorange' ? 'color-selected' : '')} onClick={this.props.onClick} style={{background : color9,}}>
                </button>
                <button value={color10} className={'btn color-button '+  (this.props.brushColor === 'oldlace' ? 'color-selected' : '')} onClick={this.props.onClick} style={{background : color10,}}>
                </button>
              </div>
            </div>
        )
    }
}