import React, { Component } from "react";
import Sketch from "../../models/Sketch";
import { Link } from "react-router-dom";
import ProfilePic from "../Profile/ProfilePic";
const avatarFallbackImage = "/images/dcasso_logo.png";

export default class ProfilePanel extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      sketchCount: 0
    };
  }

  componentDidMount() {
    this._isMounted = true;

    const { sketchUsername } = this.props;
    if (sketchUsername) {
      this.getSketchCount();
    }
  }

  componentDidUpdate(prevProps) {
    this._isMounted = true;

    const { sketchUsername } = this.props;
    if (prevProps.sketchUsername !== sketchUsername) {
      this.getSketchCount();
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  async getSketchCount() {
    const { sketchUsername } = this.props;
    const sketchCount = await Sketch.count(
      { username: sketchUsername },
      { decrypt: false }
    );
    this.setState({
      sketchCount: sketchCount
    });
  }

  isLocal() {
    const { sketchUsername, userSession } = this.props;
    if (
      userSession.isUserSignedIn() &&
      userSession.loadUserData().username === sketchUsername
    ) {
      return true;
    }
    return false;
  }

  render() {
    const { person , sketchUsername} = this.props;
    return (
      this._isMounted && <div style={{width : '100%'}}>
        <div className="profile-panel-top">
          <div className="profile-panel-photo">
          <ProfilePic person={person}/>
          </div>
          <h5>{person.name()}</h5>
            <small>{sketchUsername}</small>
          <p>{person.description()}</p>
        </div>
        <div>
          {!sketchUsername && <small>Anynomous profile is due to a log in error with Blockstack. User was supposed to create a username.</small>}
          <div className="profile-panel-info-group">
            <div style={{ paddingBottom: "5px" }}>
              <div className="profile-panel-info">
                {this.state.sketchCount} sketches
              </div>
            </div>
            {/* <div>
              <div className="profile-panel-info">
                10 <i className="far fa-grin-hearts"></i>
              </div>
              <div className="profile-panel-info">
                10 <i className="far fa-grin-squint-tears"></i>
              </div>
              <div className="profile-panel-info">
                10 <i className="fas fa-poo"></i>
              </div>
            </div> */}
          </div>
          <div style={{paddingBottom : '5px'}}>
            <Link to={`/${sketchUsername}`} className="btn btn-primary btn-sm">Visit Profile</Link>
            {this.isLocal() && (
              <a
                className="btn btn-primary btn-sm "
                href="https://browser.blockstack.org/profiles"
                target="_blank"
              >
                Edit Profile
              </a>
            )}
          </div>
        </div>
      </div>
    );
  }
}
