import React, { Component } from "react";
import Modal from "react-modal";
import SnackBarWrapper from "../Shared/SnackBarWrapper";
import { withRouter } from "react-router";

import Sketch from "../../models/Sketch";
import SketchShareModal from "./SketchShareModal";
import DeteleConfirmationModal from "../Shared/DeleteConfirmationModal";
const domain =
  process.env.NODE_ENV === "development"
    ? "http://127.0.0.1:3000"
    : "https://dcasso.app";

Modal.setAppElement("body");
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "30px"
  }
};
const shareStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    // padding: '30px',
    float: "left"
  }
};
class SketchTopMoreButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toBeDeleted: null,
      open: false,
      deleteModalIsOpen: false,
      shareModalIsOpen: false
    };
  }
  componentDidMount() {}
  /**DELETE */
  openDeleteModal() {
    this.setState({ deleteModalIsOpen: true });
  }
  async onDeleteSketch() {
    this.closeDeleteModal();

    try {
      if (this.props.match && this.props.match.params.sketchId) {
        let _sketch = await Sketch.findById(this.props.sketch.attrs._id);
        await _sketch.destroy();
      } else {
        await this.props.sketch.destroy();
      }
    } catch (e) {
      console.log("unable to delete");
    }
    //if on single sketch page, redirect
    if (this.props.match && this.props.match.params.sketchId) {
      this.props.history.push(`/`);
    } else {
      this.props.deleteSketch(this.props.sketch);
    }
  }

  openDeleteModal() {
    this.setState({ deleteModalIsOpen: true });
  }
  async onDeleteSketch() {
    this.closeDeleteModal();

    try {
      if (this.props.match && this.props.match.params.sketchId) {
        let _sketch = await Sketch.findById(this.props.sketch.attrs._id);
        await _sketch.destroy();
      } else {
        await this.props.sketch.destroy();
      }
    } catch (e) {
      console.log("unable to delete");
    }
    //if on single sketch page, redirect
    if (this.props.match && this.props.match.params.sketchId) {
      this.props.history.push(`/`);
    } else {
      this.props.deleteSketch(this.props.sketch);
    }
  }

  closeDeleteModal() {
    this.setState({ deleteModalIsOpen: false });
  }

  /**COPY POST LINK */
  getPostLink() {
    var textField = document.createElement("textarea");
    textField.innerText = `${domain}/sketch/${this.props.sketch.attrs._id}`;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    this.setOpen();
  }

  isLocal() {
    const { userSession } = this.props;
    if (userSession.isUserSignedIn()) {
      return (
        userSession.loadUserData().username === this.props.sketch.attrs.username
      );
    }
    return false;
  }

  /**
   * opens snackbar
   */
  setOpen() {
    this.setState({
      open: true
    });
  }
  handleCloseSnackBar() {
    this.setState({
      open: false
    });
  }
  /**SHARE ON SOCIAL MEDIA */
  openShareModal() {
    this.setState({ shareModalIsOpen: true });
  }

  closeShareModal() {
    this.setState({ shareModalIsOpen: false });
  }

  render() {
    return (
      <React.Fragment>
        <div className="dropdown">
          <div
            className="btn-more"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            title="Love"
          >
            <i className="fas fa-ellipsis-h"></i>
          </div>
          <div
            className="dropdown-menu sketchmore-dropdown"
            aria-labelledby="dropdownMenuButton"
          >
            {this.isLocal() && (
              <React.Fragment>
                <a
                  className="dropdown-item"
                  onClick={() => this.openDeleteModal()}
                >
                  Delete&nbsp;&nbsp;
                  <i className="fas fa-trash dcasso"></i>
                </a>
                <div className="dropdown-divider"></div>
              </React.Fragment>
            )}
            <a
              className="dropdown-item"
              href={this.props.sketch.attrs.binary}
              download
            >
              Download&nbsp;&nbsp;
              <i className="fas fa-arrow-circle-down dcasso"></i>
            </a>
            <div className="dropdown-divider"></div>
            <a className="dropdown-item" onClick={() => this.openShareModal()}>
              Share&nbsp;&nbsp;
              <i className="fas fa-share dcasso"></i>
            </a>
            <div className="dropdown-divider"></div>
            <a className="dropdown-item" onClick={() => this.getPostLink()}>
              Copy Link&nbsp;&nbsp;
              <i className="fas fa-copy dcasso"></i>
            </a>
          </div>
        </div>
        <DeteleConfirmationModal
          modalIsOpen={this.state.deleteModalIsOpen}
          closeModal={this.closeDeleteModal.bind(this)}
          sketch={this.props.sketch}
          onDeleteSketch={this.onDeleteSketch.bind(this)}
        />
        <SketchShareModal
          modalIsOpen={this.state.shareModalIsOpen}
          closeModal={this.closeShareModal.bind(this)}
          sketch={this.props.sketch}
        />
        <SnackBarWrapper
          message="Link copied to clipboard"
          open={this.state.open}
          variant="success"
          onClose={this.handleCloseSnackBar.bind(this)}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(SketchTopMoreButton);
