import React, { Component } from 'react';
import TimeStamp from './timestamp.js';
import {
  Person, lookupProfile, Profile
} from 'blockstack';
import ProfilePanel from './ProfilePanel.js';
import ProfilePic from '../Profile/ProfilePic.js';


const avatarFallbackImage = '/images/dcasso_logo.png';
export default class SketchTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      person: {
  	  	name() {
          return 'Anonymous';
        },
  	  	avatarUrl() {
  	  	  return avatarFallbackImage;
        },
        description() {
          return 'No bio'
        }
      },
      username: "",
      createdAt : ""
    }
  }
  convertDate(){
    const {sketch} = this.props;
    let timeString = TimeStamp.convertDate(sketch.attrs.createdAt);
    this.setState({
      createdAt : timeString.toLowerCase(),
    })

  }
  getProfile(){
    let username = this.props.sketch.attrs.username;
    if (username) {
      lookupProfile(username)
      .then((profile) => {
        this.setState({
          userFound :true,
          person: new Person(profile),
          username: username,
        })
      })

    }
  }
  render() {
    const {sketch} = this.props;
    const {person} = this.state;
    return(
      <div className="sketch-top-wrapper">
        <div className="btn-group dropup">

        <div className="photos-wrapper dropdown-toggle profile-toggle"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
         <ProfilePic person={person}/>
        </div>
        <div className="dropdown-menu profile-panel">
        <ProfilePanel person = {this.state.person} sketchUsername={sketch.attrs.username} userSession = {this.props.userSession}/>
        </div>
        </div>
        <div className="top-user-data">
          <div className="">
            <div  className="top-username">
              {sketch.attrs.username}
            </div>
          </div>
          <br />
          <div className="">
            <div  className="top-createdAt">
              {this.state.createdAt}
            </div>
          </div>
        </div>
      </div>
    )
  }

  componentDidMount(){
    this.convertDate();
    this.getProfile();
  }
  componentDidUpdate(prevProps){
    if (prevProps.sketch !== this.props.sketch){
      this.convertDate();
      this.getProfile();
    }
  }
}