import React, { Component } from 'react';
import Home from './Home/Home';
import Nav from './Nav'
import Signin from './Signin';
import Profile from './Profile/Profile';
import NewSketch from './NewSketch/NewSketch';
import { BrowserRouter as Router, Route, Switch, Redirect, withRouter } from "react-router-dom";
import {
  UserSession,
  AppConfig,
  config,
  Person,
} from 'blockstack';
import { User, configure, getConfig } from 'radiks';
import Reaction from '../models/Reaction';
import Sketch from '../models/Sketch';
import Contact from './Contact';
import About from './About';

import SingleSketch from './SingleSketch/SingleSketch';
import DcassoIntro from './DcassoIntro';
import SingleSketchView from './SingleSketch/SingleSketchView';
config.logLevel = 'none'

const appConfig = new AppConfig(['store_write', 'publish_data'])
const userSession = new UserSession({ appConfig: appConfig })

const apiServer = process.env.NODE_ENV === 'development' ? 'http://localhost:5001' : 'https://dcasso-server.herokuapp.com';
configure({
  apiServer: apiServer,
  userSession
});
const HomeGroup = ({match, userSession, input, handleSignIn, handleSignOut, setInput, mySketches}) => {
  
  return(
    <React.Fragment>
    <Nav userSession={userSession} handleSignOut={handleSignOut }
    setInput = {setInput}/>
    <Switch>
      <Route exact path='/'  exact={true}  render={(props) => <Home {...props} userSession={userSession} input={input} handleSignIn={ handleSignIn } />}/>
      <Route path={`/new-sketch`}   exact={true} render={(props) => <NewSketch {...props} userSession={userSession}  />}/>
      <Route path={`/sketch/:sketchId`}   exact={true} render={(props) => <SingleSketchView {...props} userSession={userSession}  />}/>      
      <Route exact path={`/contact-us`}  exact={true} component={Contact}/>  
      <Route exact path={`/about-dcasso`}  exact={true} component={About}/>            
      <Route exact path={`/:username`}  exact={true} render={(props) => <Profile {...props} userSession={userSession} mySketches = {mySketches}/>}/>            

    </Switch>
    </React.Fragment>
  );
};


class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      userSession,
      loggedIn: false,
      input : '',
      mySketches : []

    }
    
  }

  handleSignIn(e) {
    e.preventDefault();
    userSession.redirectToSignIn();
  }

  handleSignOut(e) {
    e.preventDefault();
    userSession.signUserOut(window.location.origin);
  }

  setInput(_input){
    this.setState({
      input  : _input
    })
  }
 
  
  render() {
    return (
      <div className="">
            <Switch>
            <Route exact path="/signin"   render={(props) => <Signin {...props} userSession={userSession} handleSignIn={ this.handleSignIn } />}/>
            <Route path="/"   render={(props) => <HomeGroup {...props} userSession={userSession} input={this.state.input} handleSignIn={ this.handleSignIn }
            handleSignOut={ this.handleSignOut }
            setInput = { this.setInput.bind(this) }
            mySketches = {this.state.mySketches}/>}/>
            <Route path="*"  render={() => <Redirect to ="/"/>}/>
            </Switch>
            
      </div>
    );
  }

  async componentDidMount() {
    const { userSession } = this.state;
    if(userSession.isSignInPending()) {
      await userSession.handlePendingSignIn().then(() => {
        window.location = window.location.origin;
      });
    } else if(userSession.isUserSignedIn()) {
      const userData = userSession.loadUserData();
      
      this.setState({
        loggedIn : true
      },async () => {
        await User.createWithCurrentUser()
        await this.getMySketches();
      })

    } 
  }

  async getMySketches() {
    const { userSession } = this.state;

    try {
      this.setState({ isLoading: true })
      let _mySketches = await Sketch.fetchList({ username : userSession.loadUserData().username ,sort: "-createdAt"})
      await _mySketches.forEach(sketch => {
        this.updateMySketchReaction(sketch)
      })
      this.setState({
        mySketches: _mySketches,
        isLoading: false,
      })
    } catch (e) {
      console.log(e, 'Failed to get you sketches.')
      // this.setOpen()
    }
  }
  async updateMySketchReaction(_sketch) {
    try {
      let _loveReaction = await Reaction.count({ sketchId: _sketch.attrs._id, type: 'love' })
      let _laughReaction = await Reaction.count({ sketchId: _sketch.attrs._id, type: 'laugh' })
      let _poopReaction = await Reaction.count({ sketchId: _sketch.attrs._id, type: 'poop' })
      await _sketch.update({
        loveCount : _loveReaction,
        laughCount : _laughReaction,
        poopCount  : _poopReaction
      })
      await _sketch.save()
    } catch (e) {
      console.log(e, 'Failed to update sketch.', _sketch.attrs._id)
    }
  }
}
export default withRouter(App);

