import React, { Component } from 'react';
import SnackBarWrapper from '../Shared/SnackBarWrapper';

export default class SketchShare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,

    }
  }
  getPostLink(){
    var textField = document.createElement('textarea')
    textField.innerText =  `https://dcasso.app/sketch/${this.props.sketch.attrs._id}`
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    this.setOpen()
    // console.log("get post link",window.location.href,this.props.sketchId)
  }
  /**
   * opens snackbar
   */
  setOpen() {
    this.setState({
      open: true
    })
  }
  handleCloseSnackBar() {
    this.setState({
      open: false
    })
  }

  render() {
    return (
      <React.Fragment>
      <span onClick={()=>this.getPostLink()} style={{position : "absolute", right : '10px', top : '50px'}}>
        <i className="fas fa-share-square"></i></span>
      <SnackBarWrapper
          message="Link copied to clipboard"
          open={this.state.open}
          variant="success"
          onClose={this.handleCloseSnackBar.bind(this)} />
      </React.Fragment>
    )
  }
}