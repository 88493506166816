import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Person, lookupProfile } from "blockstack";

import Comment from "../../models/Comment";
import SketchCommentInput from "./SketchCommentInput";
import SingleSketch from "./SingleSketch";
import CommentFeed from "./CommentFeed";

export default class SingleSketchView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSketchLoading: false,
            isCommentsLoading: false,
            newComment: "",
            sketch: {},
            comments: [],
        };
    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }
    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    getSketch() {

    }

    getComments() {

    }



    async getSketchComments() {
        this.setState({ isCommentsLoading: true })
        const _comments = await Comment.fetchList(
            { sketchId: this.state.sketch.attrs._id },
            { decrypt: false },
            { sort: "-createdAt" }
        );
        this.setState({ isCommentsLoading: false, comments: _comments })
    }


    render() {
        const { person } = this.state;
        return (
          <div className="singlesketchview-wrapper">
            <div className="singlesketchview">          
            <SingleSketch
                    key={this.props.match.params.sketchId}
                    match={this.props.match}
                    userSession={this.props.userSession}
            />
            <CommentFeed
              sketchId = {this.props.match.params.sketchId}
              userSession={this.props.userSession}/>
            </div>

            </div>
        );
    }

    componentDidMount() {
        const { userSession } = this.props;
        if (userSession.isUserSignedIn()) {
            this.setState({
                person: new Person(userSession.loadUserData().profile)
            });
        }
    }
}
