import React, { Component } from 'react';
import DcassoCanvas from './DcassoCanvas';
import Timer from './Timer';
import Mode from './Mode';
import RandomWord from './RandomWord.js';
import PromptModal from './PromptModal';

export default class NewSketch extends Component {

  constructor(props) {
    super(props);

    this.state = {
      timeStart: false,
      timeUp: false,
      firstDraw: true,
      randomWord: '',
      mode: 'easy',
      promptModalIsOpen : false

    }
  }
  componentDidMount() {
    const { userSession } = this.props;
    if (!userSession.isUserSignedIn()) {
      this.props.history.push('/signin')
    }
  }



  setWord(_randomWord) {
    this.setState({ randomWord: _randomWord })
  }

  startSketch(e) { //maybe have start new sketch vs tryagain, both set timestart true but have another retry function
    this.setState({
      timeStart: true
    })
  }

  setTimeUp() {
    this.setState({
      timeUp: true,
      timeStart: false
    })
  }
  setTimeRestart() {
    this.setState({
      timeStart: false,
      timeUp: false,
    })
  }
  setMode(_mode) {
    this.setState({
      mode: _mode
    })
  }

  openPromptModal(){
    this.setState({
      promptModalIsOpen : true
    })
  }

  closePromptModal(){
    this.setState({
      promptModalIsOpen : false
    })
    this.startSketch()
  }

  render() {
    const { userSession } = this.props;
    return (
      <div className="container newsketch">
        <PromptModal 
          modalIsOpen = {this.state.promptModalIsOpen}
          closeModal = {this.closePromptModal.bind(this)}
          // startSketch = {this.startSketch.bind(this)}
          randomWord = {this.state.randomWord}
          mode = {this.state.mode}
        />
        <Mode timeStart={this.state.timeStart}
          setMode={this.setMode.bind(this)}
          mode={this.state.mode}
        />
        <RandomWord
          timeStart={this.state.timeStart}
          timeUp={this.state.timeUp}
          mode={this.state.mode}
          setWord={this.setWord.bind(this)}
        />
        <Timer initialTime={this.state.initialTime}
          timeRemaining={this.state.timeRemaining}
          timeStart={this.state.timeStart}
          timeUp={this.state.timeUp}
          setTimeUp={this.setTimeUp.bind(this)}
          mode={this.state.mode}
        />
        <div>
          <DcassoCanvas history={this.props.history}
            timeStart={this.state.timeStart}
            timeUp={this.state.timeUp}
            userSession={userSession}
            mode={this.state.mode}
            word={this.state.randomWord}
            setTimeRestart={this.setTimeRestart.bind(this)}
          />
        </div>
        {!this.state.timeUp && !this.state.timeStart ?
          <button className="btn-primary button-radius button-start" onClick={e => this.openPromptModal(e)}>Start</button>
          :
          (!this.state.timeUp && this.state.timeStart ?
          <button className="btn btn-danger button-radius button-start" onClick={e => this.setTimeUp(e)}>Done</button>
          :
          null)
        }

      </div>

    )
  }
}