import React, { Component } from "react";
import { Person, lookupProfile } from "blockstack";
import TimeStamp from "./timestamp.js";
import ProfilePanel from "./ProfilePanel.js";
import ProfilePic from "../Profile/ProfilePic.js";

const avatarFallbackImage = "/images/dcasso_logo.png";

export default class CommentItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      person: {
        name() {
          return "Anonymous";
        },
        avatarUrl() {
          return avatarFallbackImage;
        },
        description() {
          return "No bio";
        }
      },
      username: "",
      createdAt: "",
      isCommentDeleting : false
    };
  }

  convertDate() {
    const { comment } = this.props;
    let timeString = TimeStamp.convertDate(comment.attrs.createdAt);
    this.setState({
      createdAt: timeString.toLowerCase()
    });
  }
  getProfile() {
    let username = this.props.comment.attrs.userId;
    if (username) {
      lookupProfile(username).then(profile => {
        this.setState({
          // userFound: true,
          person: new Person(profile),
          username: username
        });
      });
    }
  }

  componentDidMount() {
    this.convertDate();
    this.getProfile();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.comment !== this.props.comment) {
      this.convertDate();
      this.getProfile();
    }
  }

  isLocal(){
    const { comment , userSession } = this.props;
    if (userSession.isUserSignedIn() && userSession.loadUserData().username === comment.attrs.userId) {
      return true;
    }
    return false;
  }

  async deleteComment(){
    this.setState({
      isCommentDeleting : true
    })
    try {

      await this.props.comment.destroy()
      await this.props.getSketchComments()
      this.setState({
        isCommentDeleting : true
      })
    } catch(e){
      this.setState({
        isCommentDeleting : true
      })
    }
  }
  render() {
    const { comment } = this.props;
    const { person } = this.state;
    return (
      <div style={{ display: "flex", flexDirection: "row" , padding : "10px 0px"}}>
        <div style={{padding : "0px 10px"}}>
        <ProfilePic person={person}/>
        </div>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
          <div className="comment-item-top" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <span className="top-username">{comment.attrs.userId}</span>
            {/* <span>&ensp;&bull;&ensp;</span> */}
            <span className="top-createdAt"> 
            {this.state.createdAt}
            </span>
            {this.isLocal() && !this.state.isCommentDeleting && <span onClick={this.deleteComment.bind(this)}>
            <i className="fas fa-times"></i>
            </span>}
          </div>
          <div className="comment-body" style={{ justifyContent : "left"}}>
            {this.state.isCommentDeleting ? 'deleting...' : comment.attrs.body}
            </div>
        </div>
        {/* </div> */}
      </div>
    );
  }
}
