import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Person, lookupProfile } from "blockstack";
import SortInput from "./Home/SortInput";
import DcassoIntro from "./DcassoIntro";

export default class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: ""
    };
  }

  openModal(){
    this.setState({modalIsOpen: true});	
  }
  closeModal(){
    this.setState({modalIsOpen: false});	
  }
  

  render() {
    const { handleSignOut, userSession } = this.props;
    const { person } = this.state;
    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark navbar-style">
        <Link to="/" className="navbar-brand navbar-brand-margin">
          <img
            src="/images/Dcasso-word-white-border.png"
            className="logo-frame"
            width="100"
            height="100"
            alt="Dcasso logo"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <button className="nav-item btn btn-primary" onClick={this.openModal.bind(this)}>How to Dcasso</button>
        <DcassoIntro   modalIsOpen={this.state.modalIsOpen}
        closeModal = {this.closeModal.bind(this)}	  />
        <SortInput setInput={this.props.setInput} />
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav ml-auto">
            <a
              target="_blank"
              href="https://dcasso-duel.app"
              className="nav-item btn btn-primary"
              style={{ border: "1px solid white" }}
            >
              Dcasso Duel
            </a>
            {userSession.isUserSignedIn() && (
              <Link
                to={"/profile"}
                className="nav-item btn btn-primary btn-lg nav-showonphoneonly"
                id="profile-feed"
              >
                Profile
              </Link>
            )}
            <Link
              to={"/contact-us"}
              className="nav-item btn btn-primary nav-showonphoneonly"
              id="profile-feed"
            >
              Contact Us
            </Link>

            {userSession.isUserSignedIn() && (
              <React.Fragment>
                <button
                  className="nav-item btn btn-primary btn-lg nav-showonphoneonly"
                  id="signout-button"
                  onClick={handleSignOut.bind(this)}
                >
                  Logout
                </button>
                <div className="btn-group">
                  <button
                    type="button"
                    className="btn btn-primary dropdown-toggle nav-more"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    More
                  </button>
                  <div className="dropdown-menu">
                    <Link to={`/${userSession.loadUserData().username}`} className="dropdown-item" href="#">
                      <i className="fas fa-user text-primary"></i>&nbsp;Profile
                    </Link>
                    <Link to="/contact-us" className="dropdown-item">
                      <i className="fas fa-envelope text-primary"></i>
                      &nbsp;Contact Us
                    </Link>
                    <Link to="/about-dcasso">
                      <small className="dropdown-item">About Dcasso</small>
                    </Link>
                    <div className="dropdown-divider"></div>
                    <a
                      className="dropdown-item"
                      id="signout-button"
                      onClick={handleSignOut.bind(this)}
                      role="button"
                    >
                      <i className="fas fa-sign-out-alt text-primary"></i>
                      &nbsp;Log Out
                    </a>
                  </div>
                </div>
              </React.Fragment>
            )}
            {!userSession.isUserSignedIn() && (
              <Link to="/signin">
                <button
                  className="nav-item btn btn-primary btn-lg"
                  id="signout-button"
                >
                  Log In
                </button>
              </Link>
            )}
          </div>
        </div>
      </nav>
    );
  }

  componentDidMount() {
    const { userSession } = this.props;
    if (userSession.isUserSignedIn()) {
      this.setState({
        person: new Person(userSession.loadUserData().profile)
      });
    }
  }
}
