import { Model } from 'radiks';

export default class Comment extends Model {
    static className = 'Comment';
    static schema = {
        sketchId: { type: String, decrypted: true }, 
        userId: {type: String, decrypted: true },
        body: { type: String, decrypted: true }, 
    };
    static defaults = {
    };
}
