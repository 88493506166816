import React, { Component } from "react";
import Modal from "react-modal";

Modal.setAppElement('body')
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '30px',
  }
};

  export default class DeteleConfirmationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  render(){
    return(
<Modal
          isOpen={this.props.modalIsOpen}
          // onAfterOpen={this.afterOpenModal}	     
          onRequestClose={this.props.closeModal}
          style={customStyles}
          contentLabel="Delete Confirmation"
        >

          <div className="" id="exampleModal" tabIndex="" aria-labelledby="exampleModalLabel">
            <div className="" role="">
              <i className="fas fa-exclamation-circle fa-3x text-danger"></i>
              <h3 className="modal-title" id="exampleModalLabel">Are you sure?</h3>
              Your awesome artwork <b>cannot</b> be recovered once it's delete.
            <div className="footer" style={{ padding: '30px 10px 10px 10px' }}>
                <button type="button" className="btn btn-secondary" onClick={this.props.closeModal.bind(this)}>Cancel</button>
                <button type="button" className="btn btn-danger" onClick={this.props.onDeleteSketch}>Delete</button>
              </div>
            </div>
          </div>
        </Modal>
        
    )
  }
}