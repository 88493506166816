import React, { Component } from "react";
import { Person, lookupProfile } from "blockstack";
import Sketch from "../../models/Sketch";
import SingleSketch from "../SingleSketch/SingleSketch";
import NewSketchButton from "../NewSketch/NewSketchButton";
import Reaction from "../../models/Reaction";
import { withRouter } from "react-router";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackBarWrapper from "../Shared/SnackBarWrapper";
import ProfilePic from "./ProfilePic";

const avatarFallbackImage = "/images/dcasso_logo.png";

export default class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      person: {
        name() {
          return "Anonymous";
        },
        avatarUrl() {
          return avatarFallbackImage;
        }
       
      },
      mySketches: [],
      loveReacts: 0,
      laughReacts: 0,
      poopReacts: 0,
      isLoading: true,
      open: false
    };
  }

  async getMyReacts() {
    let _mySketches = this.state.mySketches;
    let _loves = 0;
    let _laughs = 0;
    let _poops = 0;
    for (const sketch of this.state.mySketches) {
      const _reactions = await Reaction.fetchList(
        { sketchId: sketch.attrs._id },
        { decrypt: false }
      );
      for (const _reaction of _reactions) {
        if (_reaction.attrs.type === "love") {
          _loves += 1;
        } else if (_reaction.attrs.type === "laugh") {
          _laughs += 1;
        } else if (_reaction.attrs.type === "poop") {
          _poops += 1;
        }
      }
    }
    this.setState({
      loveReacts: _loves,
      laughReacts: _laughs,
      poopReacts: _poops
    });
    // try {
    //   _mySketches.forEach(sketch => {
    //     _loves += sketch.attrs.loveCount;
    //     _laughs += sketch.attrs.laughCount;
    //     _poops += sketch.attrs.poopCount;
    //   })
    //   this.setState({
    //     loveReacts: _loves,
    //     laughReacts: _laughs,
    //     poopReacts: _poops,
    //   })
    // } catch (e) {
    //   console.log(e, 'failed to get your reacts')
    // }
  }

  // async getMySketches() {
  //   try {
  //     this.setState({ isLoading: true })
  //     let _mySketches = await Sketch.fetchOwnList({ sort: "-createdAt"})
  //     await _mySketches.forEach(sketch => {
  //       this.updateMySketchReaction(sketch)
  //     })
  //     this.setState({
  //       mySketches: _mySketches,
  //       isLoading: false,
  //     })
  //   } catch (e) {
  //     console.log(e, 'Failed to get you sketches.')
  //     this.setOpen()
  //   }
  // }

  setOpen() {
    this.setState({
      open: true
    });
  }

  handleCloseSnackBar() {
    this.setState({
      open: false
    });
  }

  deleteSketch(item) { 
      let _sketches = this.state.mySketches.filter(
        sketch => sketch.attrs._id !== item.attrs._id
      );
      this.setState({
        mySketches: _sketches
      });
    
  }

  // async updateMySketchReaction(_sketch) {
  //   try {
  //     let _loveReaction = await Reaction.count({ sketchId: _sketch.attrs._id, type: 'love' })
  //     let _laughReaction = await Reaction.count({ sketchId: _sketch.attrs._id, type: 'laugh' })
  //     let _poopReaction = await Reaction.count({ sketchId: _sketch.attrs._id, type: 'poop' })
  //     await _sketch.update({
  //       loveCount : _loveReaction,
  //       laughCount : _laughReaction,
  //       poopCount  : _poopReaction
  //     })
  //     await _sketch.save()
  //   } catch (e) {
  //     console.log(e, 'Failed to update sketch.', _sketch.attrs._id)
  //   }
  // }

  isLocal() {
    const { userSession } = this.props;
    if (
      userSession.isUserSignedIn() &&
      userSession.loadUserData().username === this.props.match.params.username
    ) {
      return true;
    }
    return false;
  }

  render() {
    const { handleSignOut, userSession } = this.props;
    const { person } = this.state;
    return (
      <React.Fragment>
        <div className="profile-container">
          <div className="panel-welcome">
          {this.isLocal() && <div className="profile-edit">
            <a class="btn"  data-toggle="tooltip" title="Edit Profile on Blockstack"
            href="https://browser.blockstack.org/profiles"
            target="_blank"
            >
              <i class="fas fa-user-edit"></i>
            </a>
          </div>}
            <div className="avatar-section" align="center">
            <ProfilePic person={person} classes="avatar"/>
            </div>
            <h1 className="profile-h1">
              <span id="heading-name">
                {person.name() ? person.name() : "Nameless Person"}
              </span>
            </h1>
            <p className="lead"></p>
          </div>
          {!this.state.isLoading ? (
            <div>
              {this.state.mySketches.length !== 0 ? (
                <div>
                  <div className="reaction-row">
                    <div>
                      <div data-toggle="tooltip" title="Total Sketches">
                        <i className="fas fa-palette fa-2x"></i>
                        <div>{this.state.mySketches.length}</div>
                      </div>
                      <div
                        className=""
                        data-toggle="tooltip"
                        title="Total Love Reactions"
                      >
                        <i className="far fa-grin-hearts fa-2x"></i>
                        <div>{this.state.loveReacts}</div>
                      </div>
                      <div
                        className=""
                        data-toggle="tooltip"
                        title="Total Laugh Reactions"
                      >
                        <i className="far fa-grin-squint-tears fa-2x"></i>
                        <div>{this.state.laughReacts}</div>
                      </div>
                      <div
                        className=""
                        data-toggle="tooltip"
                        title="Total Poop Reactions"
                      >
                        <i className="fas fa-poo fa-2x"></i>
                        <div>{this.state.poopReacts}</div>
                      </div>
                    </div>
                  </div>
                  <div className="home-sketch-wrapper">
                    <div>
                      {this.state.mySketches.map(sketch => (
                        <SingleSketch
                          sketch={sketch}
                          key={sketch.attrs._id}
                          userSession={this.props.userSession}
                          deleteSketch={this.deleteSketch.bind(this)}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  {this.isLocal() ? (
                    <React.Fragment>
                      <div>You don't have any sketch yet</div>
                      <NewSketchButton userSession={userSession} />
                    </React.Fragment>
                  ) : (
                    <div>{person.name()} doesn't have any sketch yet</div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div>
              <CircularProgress style={{ color: "#3700FF" }} />
              <span>Loading...</span>
            </div>
          )}
          <SnackBarWrapper
            message="There is an error. Please try again later"
            open={this.state.open}
            variant="Error"
            onClose={this.handleCloseSnackBar.bind(this)}
          />
        </div>
      </React.Fragment>
    );
  }

  componentDidMount() {
    if (this.props.match.params.username) {
      this.getProfile();

      this.fetchSketches().finally(() => {
        this.getMyReacts().finally(() => {
          this.setState({
            isLoading: false
          });
        });
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.match.params.username !== this.props.match.params.username) {
      this.getProfile();

      this.fetchSketches().finally(() => {
        this.getMyReacts().finally(() => {
          this.setState({
            isLoading: false
          });
        });
      });
    }
  }

  async fetchSketches() {
    const sketches = await Sketch.fetchList(
      { username: this.props.match.params.username, sort: "-createdAt" },
      { decrypt: false }
    );
    this.setState({
      mySketches: sketches
    });
  }

  getProfile() {
    lookupProfile(this.props.match.params.username)
      .then(profile => {
        this.setState({
          person: new Person(profile)
        });
      })
      .catch(error => {
        // this.setState({
        //   userFound : false
        // })
        this.props.history.push("/");
      });
  }
}

// export default withRouter(Profile)
