import React, { Component } from 'react';
var data = require('../../json/nounlist.json'); 

export default class RandomWord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      randomWord: '',
      mode  : ''
    }
  }

  /**
   * Helper for get word
   */
  getRandomWord(){
    let _randomWord = data[Math.floor(Math.random() * data.length)];
    this.setState({ randomWord: _randomWord})
    this.props.setWord(_randomWord);
  }

  getWord(mode){
    if (mode === 'easy') {
      data = require('../../json/nouneasy.json')
    } else if (mode === 'medium'){
      data = require('../../json/nounmedium.json')
    } else if (mode === 'hard'){
      data = require('../../json/nounhard.json')
    }
    this.getRandomWord();
  }

  
  render(){
    return(
      this.props.timeStart? 
      <div className="timer-prompt">Your prompt is  : {this.state.randomWord} </div>
      :
      ''
    )
  }
  
  componentDidMount(){
    this.getWord(this.props.mode);
  }

  componentDidUpdate(prevProps){
    if (prevProps.mode !== this.props.mode
      || (prevProps.timeUp && !this.props.timeUp)
      ) {
      this.getWord(this.props.mode);
    }
  }
}