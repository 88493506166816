import React, { Component } from 'react';

export default class Contact extends Component {
  render(){
    return(
      <div className="row ">
      <div className="col-6 mx-auto about">
      <div className="" style={{textAlign : 'left'}}>
        <h1>What's new</h1>
        <h3>v1.4</h3>
        <ul className="">
          <li className="">Ability to share individual sketch on user's social medias</li>
          <li className="">and copy link of individual sketch</li>
          <li className="">Version page</li>
        </ul>
        <h3>v1.3</h3>
        <ul className="">
          <li className="">Contact page and Dcasso's social pages</li>
        </ul>
        <h3>v1.2</h3>
        <ul className="">
          <li className="">Load more button on home screen instead of 
          fetching all sketches at the beginning, to somewhat increase speed</li>
        </ul>
        <h3>v1.1</h3>
        <ul className="">
          <li className="">Ability to finish sketch early</li>
        </ul>
        <h3>v1.0</h3>
        <ul className="">
          <li className="">Initial release of Dcasso</li>
          <li className="">Ability to add new sketch, choose mode, have timing and random prompt</li>
          <li className="">Ability to discard newly drawn sketch</li>
          <li className="">Ability to delete your own sketches</li>
          <li className="">Ability to filter sketches by prompt</li>


        </ul>
        {/* <h1>about</h1> */}

      </div>
      </div>
      </div>

    )
  }
}