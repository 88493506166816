import { Model } from 'radiks';
import Reaction from '../models/Reaction';

export default class Sketch extends Model {
  static className = 'Sketch';
  static schema = {
    username: { type: String, required: true, decrypted: true, },
    binary: { type: String, required: true, decrypted: true },
    // compressedBinary: { type: Buffer, required: true, decrypted: true },
    type: { type: String, decrypted: true }, //type : game or ___might be removed
    prompt: { type: String, required: true, decrypted: true },
    mode: { type: String, required: true, decrypted: true }, //easy, medium, hard
    // speed: { type: String, decrypted: true },
    viewCount: { type: Number, decrypted: true },
    // reaction: { type: Reaction, decrypted: true },
    reactionCount: { type: Number, decrypted: true },
    loveCount: { type: Number, decrypted: true },
    laughCount: { type: Number, decrypted: true },
    poopCount: { type: Number, decrypted: true },

  };
  static defaults = {
    reactionCount:0 ,
    loveCount: 0,
    laughCount: 0,
    poopCount: 0,
    viewCount: 0,
  };
}
