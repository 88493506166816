import React, { Component } from "react";
import Modal from "react-modal";
import CountDown from "../Shared/CountDown";

Modal.setAppElement("body");
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "30px",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    background: "#3700FF",
    color: "white",
    fontSize: "50px"
  }
};

export default class PromptModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      time: 20
    };
  }
  componentDidMount() {
    this.modeToTime()
  }

  componentDidUpdate(prevProps){
    if (prevProps.mode !== this.props.mode){
      this.modeToTime()
    }
  }

  modeToTime(){
    const { mode } = this.props;
    if (mode === "easy") {
      this.setState({
        time: 20
      });
    } else if (mode === "medium") {
      this.setState({
        time: 30
      });
    } else if (mode === "hard") {
      this.setState({
        time: 40
      });
    }
  }
  timesUp() {
    this.props.closeModal();
  }
  render() {
    return (
      <Modal
        isOpen={this.props.modalIsOpen}
        // onAfterOpen={this.afterOpenModal}
        onRequestClose={this.props.closeModal}
        style={customStyles}
        contentLabel="Prompt Confirmation"
        // overlayClassName= {{
        //   base: "prompt-modal",
        //   afterOpen: "prompt-modal--after-open",
        //   beforeClose: "prompt-modal--before-close"
        // }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          Draw
          <span>
            <code style={{background : 'white', padding : '10px'}}>{this.props.randomWord}</code>
          </span>
          in less than {this.state.time} seconds
          <CountDown
            time="5"
            timesUp={this.timesUp.bind(this)}
            fontSize="2rem"
          />
        </div>
      </Modal>
    );
  }
}
