import React, { Component } from "react";

export default class Timer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      randomNoun: "",
      timeRemaining: 0,
      mode: ""
    };
  }

  setTimeEasy() {
    this.setState({
      timeRemaining: 20
    });
  }

  setTimeMedium() {
    this.setState({
      timeRemaining: 30
    });
  }

  setTimeHard() {
    this.setState({
      timeRemaining: 40
    });
  }

  setTimeByMode(mode) {
    if (mode === "easy") {
      this.setTimeEasy();
    } else if (mode === "medium") {
      this.setTimeMedium();
    } else if (mode === "hard") {
      this.setTimeHard();
    }
  }
  tick() {
    let sec = this.state.timeRemaining - 1;
    this.setState({
      timeRemaining: sec
    });
    if (sec === 0) {
      this.stopTimer();
    }
  }
  stopTimer() {
    clearInterval(this.handleInterval);
    this.setState(
      {
        timeRemaining: 0
      },
      this.props.setTimeUp()
    );
  }
  handleInterval;
  startCountDown(e) {
    this.handleInterval = setInterval(e => this.tick(e), 1000);
  }

  render() {
    return (
      <div className="timer-text-wrapper">
        {!this.props.timeUp ? (
          <React.Fragment>
            <div>
              <small>Time left</small>
            </div>
            <div className="timer-text">{this.state.timeRemaining}</div>
          </React.Fragment>
        ) : (
          <div className="timer-text">Times Up!</div>
        )}
      </div>
    );
  }
  componentDidMount() {
    this.setTimeByMode(this.props.mode);
  }
  componentDidUpdate(prevProps) {
    if (
      (!this.props.timeStart &&
        !this.props.timeUp &&
        prevProps.timeUp !== this.props.timeUp) ||
      prevProps.mode !== this.props.mode
    ) {
      this.setTimeByMode(this.props.mode);
    }

    //when timer has started and previous timer was false => restart the clock
    if (this.props.timeStart && prevProps.timeStart !== this.props.timeStart) {
      this.startCountDown();
    }

    //when timer has started and we ended it (timeUp = true) => end the clock
    if (this.props.timeUp && prevProps.timeUp !== this.props.timeUp) {
      this.stopTimer();
    }
  }
}
