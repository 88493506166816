import React, { Component } from "react";
import { Link } from "react-router-dom";
import SketchShareModal from "./SketchShareModal";
import Comment from "../../models/Comment";
import Sketch from "../../models/Sketch";
import { withRouter } from "react-router";

import DeteleConfirmationModal from "../Shared/DeleteConfirmationModal";
import SnackBarWrapper from "../Shared/SnackBarWrapper";

import { saveAs } from 'file-saver';

class SketchMoreBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      commentCount : 0,
      shareModalIsOpen: false,
      deleteModalIsOpen: false,
      errorSnackbarOpen: false,

    };
  }
  componentDidMount(){
    this.getCommentCount()
  }

  async getCommentCount(){
    const count = await Comment.count({sketchId : this.props.sketch._id}, {decrypt : false})
    await this.setState({
      commentCount : count
    })
  }

  isLocal(){
    const { sketch , userSession } = this.props;
    if (userSession.isUserSignedIn() && userSession.loadUserData().username === sketch.attrs.username) {
      return true;
    }
    return false;
  }

  /**DELETE */
  openDeleteModal() {
    this.setState({ deleteModalIsOpen: true });
  }
  async onDeleteSketch() {
    this.closeDeleteModal()

    try {
      await this.props.sketch.destroy()

      // if (this.props.match && this.props.match.params.sketchId) {
      //   let _sketch = await Sketch.findById(this.props.sketch.attrs._id)
      //   await _sketch.destroy()
      // } else {
      //   await this.props.sketch.destroy()
      // }
    } catch (e) {
      console.log("unable to delete")
    }
    //if on single sketch page, redirect
    if (this.props.match && this.props.match.params.sketchId) {
      this.props.history.push(`/`)
    } else {
      this.props.deleteSketch(this.props.sketch)
    }
  }
  closeDeleteModal() {
    this.setState({ deleteModalIsOpen: false });
  }
  async onDeleteSketch() {
    try {
      // console.log(this.props.sketch)
      let _sketch = await Sketch.findById(this.props.sketch.attrs._id)
        await _sketch.destroy()
        // await this.props.sketch.destroy()
        .then((response)=>{
          this.closeDeleteModal()

          if (response) {
            //if on single sketch page, redirect
            if (this.props.match && this.props.match.params.sketchId) {
              this.props.history.push(`/`)
            } else {
              this.props.deleteSketch(this.props.sketch)
            }
          }else {
            this.openErrorSnackbar()
          }
        })
      // if (this.props.match && this.props.match.params.sketchId) {
      //   let _sketch = await Sketch.findById(this.props.sketch.attrs._id)
      //   await _sketch.destroy()
      // } else {
      //   await this.props.sketch.destroy()
      // }
    } catch (e) {
      console.log("unable to delete")
    }
    
  }

  /**SHARE ON SOCIAL MEDIA */
  openShareModal() {
    this.setState({ shareModalIsOpen: true });
  }

  closeShareModal() {
    this.setState({ shareModalIsOpen: false });
  }
  download() {
    var byteString = atob(this.props.sketch.attrs.binary.split(',')[1]);

    // separate out the mime component
    // var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  
      // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], {type: "image/jpeg"});
    saveAs(blob, "dcasso-sketch.jpg");
  }

  openErrorSnackbar(){
    this.setState({
      errorSnackbarOpen : true
    })
  }
  closeErrorSnackbar(){
    this.setState({
      errorSnackbarOpen : false
    })
  }
  render() {
    return (
      <React.Fragment>
        <div className="sketch-more-bar">
          {/* <span className="text-secondary"><i className="fas fa-file-download"></i></span> */}
          <Link
            className="sketch-more-button"
            to={`/sketch/${this.props.sketch.attrs._id}`}
            data-toggle="tooltip" title="Comment"
          >
            {this.state.commentCount} <i className="fas fa-comment-alt fa-2x"></i>
          </Link>
          <span className="sketch-more-button" onClick={this.download.bind(this)}
          data-toggle="tooltip" title="Download sketch">
            <i className="fas fa-level-down-alt fa-2x"></i>
          </span>
          <span className="sketch-more-button" onClick={() => this.openShareModal()} 
          data-toggle="tooltip" title="Share sketch">
            <i className="fas fa-share-alt fa-2x"></i>
          </span>
          {this.isLocal() && <span className="sketch-more-button"
            data-toggle="tooltip" title="Delete sketch"
            onClick={() => this.openDeleteModal()}>
            <i className="fas fa-trash fa-2x"></i>
          </span>}
        </div>
        <SketchShareModal
          modalIsOpen={this.state.shareModalIsOpen}
          closeModal={this.closeShareModal.bind(this)}
          sketch={this.props.sketch}
        />
        <DeteleConfirmationModal
        modalIsOpen={this.state.deleteModalIsOpen}
        closeModal={this.closeDeleteModal.bind(this)}
        sketch={this.props.sketch}
        onDeleteSketch={this.onDeleteSketch.bind(this)}
        />
         <SnackBarWrapper
          message="There is an error. Please try again later"
          open={this.state.errorSnackbarOpen}
          variant="error"
          onClose={this.closeErrorSnackbar.bind(this)} />
      </React.Fragment>
    );
  }
}
export default withRouter(SketchMoreBar)