import React, { Component } from "react";
import Modal from "react-modal";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  VKShareButton,
  OKShareButton,
  RedditShareButton,
  TumblrShareButton,
  LivejournalShareButton,
  MailruShareButton,
  ViberShareButton,
  WorkplaceShareButton,
  LineShareButton,
  PocketShareButton,
  InstapaperShareButton,
  EmailShareButton,

  FacebookShareCount,

  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  PinterestIcon,
  VKIcon,
  OKIcon,
  TelegramIcon,
  WhatsappIcon,
  RedditIcon,
  TumblrIcon,
  MailruIcon,
  EmailIcon,
  LivejournalIcon,
  ViberIcon,
  WorkplaceIcon,
  LineIcon,
  PocketIcon,
  InstapaperIcon,
} from 'react-share';
import SnackBarWrapper from "../Shared/SnackBarWrapper";

const domain = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:3000' : 'https://dcasso.app';

Modal.setAppElement('body')
const shareStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    borderRadius : '10px',
    // padding: '30px',
    float: 'left'
  }
};
export default class SketchShareModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }
  /**COPY POST LINK */
  getPostLink() {
    var textField = document.createElement('textarea')
    textField.innerText = `${domain}/sketch/${this.props.sketch.attrs._id}`
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    this.setOpen()
  }

  /**
   * opens snackbar
   */
  setOpen() {
    this.setState({
      open: true
    })
  }
  handleCloseSnackBar() {
    this.setState({
      open: false
    })
  }

  render() {
    return (
      <React.Fragment>
      <Modal
        isOpen={this.props.modalIsOpen}
        onRequestClose={this.props.closeModal}
        style={shareStyles}
        contentLabel="Delete Confirmation"
      >
        <div className="share-modal">
          <div className="share-top">
            <h3>Share</h3>
        <small>Share this awesome masterpiece on social media</small>
        </div>
        <div className="share-options">
          <div onClick={this.getPostLink.bind(this)}>
            <div className="copy"><i className="fas fa-copy"></i></div>
            Copy Link
          </div>
          <div>
          <FacebookShareButton
            url={`${domain}/sketch/${this.props.sketch.attrs._id}`}
            quote="Check out my masterpiece on Dcasso.app"
            className="social-butt"
          >
            <FacebookIcon size={50} round />
          </FacebookShareButton>
          Share to Facebook
          </div>
          <div>
          <TwitterShareButton
            url={`${domain}/sketch/${this.props.sketch.attrs._id}`}
            quote="Check out my masterpiece on Dcasso.app"
            className="social-butt"
          >
            <TwitterIcon size={50} round />
          </TwitterShareButton>
          Share to Twitter
          </div>
          <div>
          <TelegramShareButton
            url={`${domain}/sketch/${this.props.sketch.attrs._id}`}
            quote="Check out my masterpiece on Dcasso.app"
            className="social-butt"
          >
            <TelegramIcon size={50} round />
          </TelegramShareButton>
          Share to Telegram
          </div>
          <div>

          <WhatsappShareButton
            url={`${domain}/sketch/${this.props.sketch.attrs._id}`}
            quote="Check out my masterpiece on Dcasso.app"
            className="social-butt"
          >
            <WhatsappIcon size={50} round />
          </WhatsappShareButton>
          Share to Whatsapp

          </div>
        </div>
        </div>
      </Modal>
     <SnackBarWrapper
     message="Link copied to clipboard"
     open={this.state.open}
     variant="success"
     onClose={this.handleCloseSnackBar.bind(this)} />
     </React.Fragment>
    );
  }
}
