import React, { Component } from "react";
import Comment from "../../models/Comment";
import { Smile } from "react-feather";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";

const TEXT_LIMIT = 100;
export default class SketchCommentInput extends Component {
  // onChangeHandler(e) {
  //     this.props.setNewComment(e);
  // }
  constructor(props) {
    super(props);
    this.state = {
      newComment: "",
      characters: 0,
      showEmojiPicker: false
    };
  }
  async postComment(e) {
    const { userSession } = this.props;
    if (e.key === "Enter") {
      e.preventDefault();
      let _newComment = new Comment({
        sketchId: this.props.sketchId,
        userId: userSession.loadUserData().username,
        body: this.state.newComment
      });
      try {
        await _newComment.save().then(() => {
          this.setState({
            newComment: "",
            characters: 0
          });
          this.props.addComment(_newComment);
        });
      } catch (e) {
        console.error(e);
      }
    }
    //  else {
    //   this.setNewComment(e)
    // }
  }
  addEmoji(emoji) {
    this.setState({
      newComment: this.state.newComment + emoji.native,
      characters: this.state.characters + 1
    });
  }
  setNewComment(event) {
    // console.log(event.target, event.keyCode, event.key)
    if (this.state.characters < TEXT_LIMIT) {
      this.setState({
        newComment: event.target.value,
        characters: event.target.value.length
      });
      if (event.target.value.length >= TEXT_LIMIT) {
      }
    }
  }

  handleChange(event) {
    var appropriateLengthWords = event.target.value.substring(
      0,
      TEXT_LIMIT - 1
    );
    this.setState({
      newComment: appropriateLengthWords,
      characters: TEXT_LIMIT - event.target.value.length
    });
  }
  toggleEmojiPicker() {
    this.setState({
      showEmojiPicker: !this.state.showEmojiPicker
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="sketch-comment">
          <div className="sketch-comment-input">
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: " column "
              }}
            >
              <textarea
                id="commentInput"
                type="text"
                className="form-control"
                placeholder="Drop a thought..."
                value={this.state.newComment}
                onChange={this.handleChange.bind(this)}
                onKeyDown={event => this.postComment(event)}
              />
              <div style={{ alignSelf: "flex-end" }}>
                <small>
                  {this.state.characters}/{TEXT_LIMIT}
                </small>
              </div>
            </div>
            <div
              className="emoji-div"
              style={{ alignSelf: "flex-start", position: "relative" }}
            >
              <button
                type="button "
                className="btn btn-emoji"
                data-toggle="tooltip"
                title="Insert an emoji"
                onClick={this.toggleEmojiPicker.bind(this)}
                style={{ paddingTop: "5px" }}
              >
                <Smile size={"24"} />
              </button>
              {this.state.showEmojiPicker && (
                <Picker
                  set="emojione"
                  onSelect={this.addEmoji.bind(this)}
                  backgroundImageFn={(set, sheetSize) => {
                    return "/images/emojione-32.png";
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
