import React, { Component } from "react";
import { Person, lookupProfile } from "blockstack";

import Comment from "../../models/Comment";
import CommentItem from "./CommentItem";
import SketchCommentInput from "./SketchCommentInput";

export default class CommentFeed extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isCommentsLoading: false,
        // newComment: "",
        sketch: {},
        comments: [],
    };
}
  componentDidMount(){
    this.getSketchComments()
  }

  async getSketchComments() {
    this.setState({ isCommentsLoading: true });
    const _comments = await Comment.fetchList(
      { sketchId: this.props.sketchId },
      { decrypt: false },
      { sort: "-createdAt" }
    );
    this.setState({ isCommentsLoading: false, comments: _comments });
  }
  addComment(newComment){
    this.setState({
      comments : [...this.state.comments, newComment]
    })
  }

 
  render() {
    // const { person, userSession } = this.props;
    return (
      <div className="comment-card-wrapper" style={{display : 'flex', flexDirection : 'column'}}>
        <div className="comment-card">
        {this.props.userSession.isUserSignedIn() && <SketchCommentInput
          userSession = {this.props.userSession}
          sketchId={ this.props.sketchId}
          addComment={this.addComment.bind(this)}
        />}
        <div className="comment-feed-wrapper">
          {(this.state.comments.length === 0) && 
          <div className="no-comment">
          <i>Be the first to comment :)</i>
          </div>}
          {this.state.comments.map((comment, i) => {
            return <CommentItem 
            key={comment.attrs._id}
            comment={comment}
            userSession = {this.props.userSession}
            getSketchComments = {this.getSketchComments.bind(this)}/>;
          })}
        </div>
        </div>
      </div>
    );
  }
}
