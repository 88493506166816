import React, { Component } from 'react';

export default class SortInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input : ''
    }
  }
  onChangeHandler(e){
    this.setState({
      input: e.target.value,
    })
    this.props.setInput(e.target.value);
  }

  render(){
    return(
      <React.Fragment>
        <input id="filterByPrompt" type="text" className="form-control nav-filter" style={{}} placeholder="Filter Sketches by Prompt"
        value= {this.state.input} onChange={this.onChangeHandler.bind(this)}/>
      </React.Fragment>
    )
  }


}