import React, { Component } from 'react';
import Modal from 'react-modal';
Modal.setAppElement('body')
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding               : '0px'
  }
};
export default class DcassoIntro extends Component {
  render(){
    return(
      <Modal
      isOpen={this.props.modalIsOpen}	      
      onRequestClose={this.props.closeModal}	      
      style={customStyles}
      contentLabel="Log In"	    
      >
      <div className="intro-bg">
        <div className="intro">
          <div>
          <h3 style={{fontFamily:'cursive'}}>What is Dcasso?</h3>
          </div>
          <div>
          <p>Dcasso is a <b>fun</b> and <b>judgement-free</b> drawing platform.<br/> 
          &#8226; It's <i>not</i> about drawing the best sketch, because there are many tools out there to serve that purpose. <br/>
          &#8226; Dcasso is a game where you're given a <b>random prompt</b> and a set <b>time</b>, you're tested on how <b>fast</b> and <b>creative</b> you can come up with a drawing.<br/>
          &#8226; We don't include the definition for the prompt for the same reason. A word can have more than one meanings/implications. We love to see different drawings of the same prompt.<br/>
          If you don't know what the prompt means, you can always finish the drawing early and restart.
          </p>
          <p style={{fontFamily:'cursive'}}>Enjoy!</p>
          </div>
        </div>
      </div>
      </Modal>
    )
  }
}