import React, { Component } from 'react';
import { Link } from "react-router-dom";

export default class NewSketchButton extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      this.props.userSession.isUserSignedIn() ?
      <Link to='/new-sketch' >
        <button className="btn-primary button-radius button-start bold-weight btn-new-sketch">
          Add your own Sketch
        </button>
      </Link>
      :
      <Link to='/signin'>
        <button className="btn-primary button-radius button-start bold-weight btn-new-sketch">
        Add your own Sketch <br/>
      </button>
      <br />
      <small className="gotta-sign-in-text">(You gotta sign in first)</small>
      </Link>
    )
  }
}