import React, { Component } from 'react';

export default class Mode extends Component {
  
  constructor(props) {
    super(props);
  }
  setMode(mode){
    this.props.setMode(mode);
  }
  render(){
    return(
      <React.Fragment>
        <div>
        <small>Select a Mode</small>
        </div>
        <button className={'btn btn-warning button-radius bold-weight mode-button '+(this.props.mode === 'easy' ? 'active' : '')}
          onClick={()=>this.props.setMode('easy')}
          disabled={this.props.timeStart}>
          Easy
        </button>
        <button className={'btn btn-warning button-radius bold-weight mode-button '+(this.props.mode === 'medium' ? 'active' : '')}
          onClick={()=>this.props.setMode('medium')}
          disabled={this.props.timeStart}>
          Medium
        </button>
        <button className={'btn btn-warning button-radius bold-weight mode-button '+(this.props.mode === 'hard' ? 'active' : '')}
          onClick={()=>this.props.setMode('hard')}
          disabled={this.props.timeStart}>          
          Hard
        </button>
      </React.Fragment>
    )
  }
}